import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InstaGrid from "../components/instagramGrid"

import "../styles/index.scss"

export default () => {
  const image = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "heroImg.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <section class="hero is-fullheight">

        <div class="hero-body" style={{ padding: '0px', alignItems: 'none' }}>
          <div class="container" style={{ margin: `0px` }}>
            <Img style={{
              height: '130vh',
              width: `100vw`,
              zIndex: '-5',
              position: 'relative'
            }}
              loading="lazy"
              fluid={image.hero.childImageSharp.fluid}
              alt="Search Icon"
            />
            <div className="heroContent">
              <div className="productName">
                Performance Tees
              </div>
              <a href="http://healthhitters.com/comingSoon/"
                className="shopNowButton">
                Shop Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bannerSection">
        <div className="banner">
          <div className="bannerContent">
            Checkout our flavors
            </div>
          <a href="http://healthhitters.com/comingSoon/" 
            className="shopNowButton">
            Shop Now
            </a>
        </div>
      </section>
      <InstaGrid />
    </Layout>
  )
}