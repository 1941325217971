import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import "../styles/index.scss"

const nodeURL = 'https://www.instagram.com/p';

export default () => {

    const {
        allInstaNode: { edges }
      } = useStaticQuery(graphql`
        {
          allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 8) {
            edges {
              node {
                id
                caption
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      `);

      const firstFourImage = edges.slice(0, 4).map(({ node }) => {
        const {
          id,
          caption,
          localFile: { childImageSharp },
        } = node;
        return (
          <div class="column" id="instaTopColumn" key={id}>
            <a id="instaPost" href={`${nodeURL}/${id}`} target="_blank" rel="noopener noreferrer">
              <Img style={{
                width: '100%',
                height: '100%',
                maxHeight: '23rem',
              }}
                loading="lazy"
                alt={caption || ''}
                fluid={childImageSharp.fluid}
              />
            </a>
          </div>
        );
      });

      const secondFourImage = edges.slice(4).map(({ node }) => {
        const {
          id,
          caption,
          localFile: { childImageSharp },
        } = node;
        return (
          <div class="column" id="instaBottomColumn" key={id}>
            <a id="instaPost" href={`${nodeURL}/${id}`} target="_blank" rel="noopener noreferrer">
              <Img style={{
                width: '100%',
                height: '100%',
                maxHeight: '23rem',
              }}
                loading="lazy"
                alt={caption || ''}
                fluid={childImageSharp.fluid}
              />
            </a>
          </div>
        );
      });

    return (
        <div>
            <div class="columns is-gapless is-mobile" id="instaColumns">
                {firstFourImage}
            </div>
            <div class="columns is-gapless is-mobile" id="instaColumns">
                {secondFourImage}
            </div>
        </div>
    );
};